<template>
    <div class="dropdown ms-sm-3 header-item topbar-user">
        <button
            type="button"
            class="btn"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <span class="d-flex align-items-center">
                <img
                    class="rounded-circle header-profile-user"
                    :src="absoluteUrl(activeUser.image)"
                    alt="Header Avatar"
                />
                <i class="ri-arrow-drop-down-line"></i>
            </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <h6 class="dropdown-header">Welcome {{activeUser.first_name}}!</h6>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/settings/profile"
                ><i
                    class="bx bx-user-circle me-2 text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Profile</span></router-link
            >
            <a class="dropdown-item" @click.prevent="logout()" href="/logout"
                ><i
                    class=" bx bx-power-off me-2 text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle" data-key="t-logout">Log out</span></a
            >           
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        activeUser() {
           return this.$store.state.AppActiveUser;
        },
    },
    methods:{
        logout(){
            this.$store.dispatch("logoutUser")
            this.$router.push({path: '/login'})
        }
    }
};
</script>
